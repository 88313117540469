module.exports = (loggerName) => {
    console.debug(`creating logger '${loggerName}'`);

    const actualLoggerName = `${loggerName}:`;

    const logger = {
        debug: (message) => console.debug(actualLoggerName, message),
        info: (message) => console.info(actualLoggerName, message),
        log: (message) => console.log(actualLoggerName, message),
        warn: (message) => console.warn(actualLoggerName, message),
        error: (message) => console.error(actualLoggerName, message),
    };

    return logger;
};
